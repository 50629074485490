<template>
  <div v-if="demo === '2'" class="equation">
    <div class="banner" v-if="dataList">
      <div v-if="equation" class="banner_beijing">
        <img :src="equation.cardImage" alt="" />
        <div
          class="apply-btns"
          :style="{
            top: path === '/trends/recruit' ? 'calc(50%)' : 'calc(50% - 24px)',
          }"
          v-if="data.linkUrl"
        >
          <a :href="data.linkUrl" target="_blank">立即投递</a>
          <!-- <a href="https://campus.nio.com" target="_blank">2022届春季校招</a> -->
        </div>
      </div>
      <div class="banner_img" v-for="(i, k) in dataList" :key="k">
        <img :src="i.cardImage" alt="" />
      </div>
    </div>
    <!--            <div v-if="dataList" class="equation_content">-->
    <!--                <p v-html="dataList.cardContent"></p>-->
    <!--            </div>-->
  </div>
</template>
<script>
import {
  getfescpithy,
  getWebformula,
  getWebnio_u,
  getWebAutumnRecurit,
  getWebPublicity
} from "@/api/commonApi";

export default {
  data() {
    return {
      dataList: null,
      tableData: null,
      demo: null,
      equation: {
        cardImage: null,
      },
      data: null,
      loading: false,
      disabledPlay: true,
      path: this.$route.path,
    };
  },
  created() {
    if (this.$route.query.demo) {
      this.demo = this.$route.query.demo;
    }
    this.fescpithy();
  },
  methods: {
    fescpithy() {
      if (
        this.path === "/trends/recruit" ||
        this.path === "/trends/equation" ||
        this.path === "/trends/nio_u" ||
        this.path === "/trends/autumnRecruit"||
        this.path === "/trends/webPublicity"
      ) {
        let func =
          this.path === "/trends/recruit"
            ? getWebformula()
            : this.path === "/trends/equation"
            ? getfescpithy()
            : this.path === "/trends/nio_u"
            ? getWebnio_u()
            : this.path === "/trends/autumnRecruit"
            ? getWebAutumnRecurit()
            : getWebPublicity();
        func.then(([res]) => {
          if (res.success) {
            this.data = res.data[0];
            this.dataList = res.data[0].tbCardListModelList;
            this.equation = this.dataList[0];
            let id1 = this.dataList.findIndex((item) => {
              if (item.cardIndex == 1) {
                return true;
              }
            });
            this.dataList.splice(id1, 1);
          }
        });
      }
    },
    //跳转
    getDelivery(data) {
      window.open(data);
    },
  },
};
</script>
<style lang="less" scoped>
.apply-btns {
  display: flex;
  justify-content: center;
  position: absolute;
  margin: 72px auto 0;
  width: 476px;
  top: calc(50% - 24px);
  left: calc(50% - 233px);
  transform: translate();
  a {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 222px;
    height: 48px;
    // line-height: 48px;
    // text-align: center;
    border-radius: 2px;
    background-color: transparent;
    transition: background-color 0.3s;

    &:first-child {
      background-color: #fff;
      color: #00b3be;
      &:hover {
        background-color: #00b3be;
        color: #fff;
      }
    }
    &:last-child {
      line-height: 46px;
      background-color: rgba(255, 255, 255, 0.1);
      color: #ffffff;
      &:hover {
        border: 1px solid #00b3be;
        background-color: #00b3be;
        color: #fff;
      }
    }
  }
}
.equation {
  width: 100%;
  height: auto;
  .banner {
    width: 100%;
    height: auto;
    z-index: 1;
    .banner_beijing {
      width: 100%;
      height: auto;
      position: relative;
      img {
        width: 100%;
        height: auto;
        object-fit: cover;
      }
    }
    .banner_img {
      height: auto;
      width: 1200px;
      margin: 0px auto;
      img {
        width: 100%;
        height: auto;
        object-fit: cover;
        z-index: -1;
      }
    }
    h1 {
      font-size: 30px;
      font-weight: bold;
      text-align: center;
      color: #000000;
      padding: 50px;
    }
    .title {
      p {
        width: 100%;
        text-align: center;
        font-size: 24px;
        line-height: 50px;
        color: #000000;
      }
    }
    .delivery {
      margin: 50px auto;
      width: 300px;
      height: 60px;
      border-radius: 30px;
      border: 1px solid greenyellow;
      background-color: #ffffff;
      text-align: center;
      p {
        font-size: 25px;
        font-weight: 100;
        cursor: pointer;
        color: #00ae9d;
        line-height: 60px;
      }
    }
  }
  .equation_content {
    width: 100%;
    padding: 50px 0px;
    p {
      line-height: 35px;
      text-align: center;
      font-size: 16px;
      color: #000000;
    }
  }
}
</style>
